@use "../../utils" as *;

.two-pgph-block {
  h3{
    margin-bottom: 1.5rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 1.667vw;
    }
    @include for-screen-down($tablet-m){
      margin-bottom: 1rem;
    }
  }
  .two-paragraph-grid {
    @include for-screen-up($tablet-m) {
      display: grid;
      grid-template-columns: repeat(2, 49.25%);
      gap: 1.5%;
    }
    p {
      padding-right: 20px;
      @include for-screen-up($desktop-l) {
        padding-right: 1.39vw;
      }
      @include for-screen-down($tablet-m) {
        padding-right: 0;
        &:first-of-type {
          margin-bottom: 24px;
        }
      }
    }
  }
}

.title-pgph-sct{
  @include for-screen-up($tablet-m) {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 4%;
  }
  h3{
    @include for-screen-down($tablet-m){
      margin-bottom: 16px;
    }
  }
  p:not(:last-of-type){
    margin-bottom: 1.5rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 1.667vw;
    }
    @include for-screen-down($tablet-m){
      margin-bottom: 1rem;
    }
  }
}
