html, body, *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

ul{
  list-style-type: none;
}

button{
  border: none;
  outline: none;
}

img{
  display: block;
}