@use 'src/styles/utils/index' as *;

/* Specialities */
.about-specialities {
  h2 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .speciality-description__service {
    border-radius: 1.5rem;
    height: 100%;
    padding: 2rem 1.5rem;
    transition: box-shadow .7s ease;
    @include for-screen-up($desktop-l){
      border-radius: 1.66vw;
      padding: 2.22vw 1.66vw;
    }
    @include for-screen-down($desktop-l) {
      width: 282px;
    }
    a {
      opacity: 0;
      transition: opacity .7s ease;
    }
    @media(hover: hover){
      &:hover{
        box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14);
        a {
          opacity: 1;
        }
      }
    }
    .speciality-lottie{
      height: 5rem;
      width: 5rem;
      margin: 0 !important;
      position: relative;
      left: -8px;
      @include for-screen-up($desktop-l){
        width: 5.5vw;
        height: 5.5vw;
      }
    }
    h4 {
      @extend %title4;
      color: $text-scale;
      margin: 1.5rem 0 .5rem;
    }

    p {
      @extend %body3;
      color: $text-scale-light;
    }
    .link{
      cursor: pointer;
      display: inline-block;
      margin-top: 1rem;
      @extend %body4;
      color: $color-primary;
    }
  }

  /*  Swiper  */
  swiper-container{
    &::part(container) {
      padding: 0 8.34vw 80px;
      transform: translate(-8.34vw);
    }
    .swiper-slide {
      padding-top: 2.77vw;
      @include for-screen-down($tablet-l){
        padding-top: 2.5rem;
      }
      @include for-screen-down($tablet-l){
        padding-top: 2rem;
      }
      @include for-screen-up($desktop-l){
        width: 23.5% !important;
      }
      @include for-screen-down($desktop-l){
        width: auto !important;
      }
      @media (hover: none) and (max-width: $desktop-s){
        &.swiper-slide-active .speciality-description__service{
          box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14);
          a {
            opacity: 1;
          }
        }
      }
    }
    &::part(pagination){
      @include for-screen-up($desktop-s) { //703 pour avoir exactement les 4 points de pagination
        display: none !important;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      width: 100px;
      top: calc(100% - 80px);
      left: 8.34vw;
    }
    &::part(bullet), &::part(bullet-active) {
      background-color: $text-scale !important;
      width: 6px;
      height: 6px;
    }
    &::part(bullet) {
      opacity: .1;
    }
    &::part(bullet-active) {
      opacity: 1;
    }
  }
}


