@use 'src/styles/utils/index' as *;

.modal-wrapper{
  background: $background;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
.modal-speciality{
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
  @include for-screen-up($desktop-s){
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  @include for-screen-down($desktop-s){
    grid-template-columns: 100%;
    overflow: auto;
  }
  .modal-content-wrapper{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    z-index: 1;
    @include for-screen-up($desktop-s){
      max-height: 100vh;
      padding: 2.77vw 2.77vw 2.22vw;
      box-shadow: 0 0 80px 0 #00000033;
    }
    @include for-screen-down($desktop-s){
      gap: 60px;
      grid-template-rows: repeat(3, auto);
      padding: 40px 24px;
      box-shadow: 0 0 80px 0 #00000033;
    }
    h3{
      @extend %title5;
      color: $text-scale;
      margin-bottom: 1rem;
      @include for-screen-up($desktop-l){
        margin-bottom: 1.111vw;
      }
    }
  }
  .modal-header{
    @include for-screen-up($desktop-s){
      margin-bottom: 2.22vw;
    }
    p{
      @extend %body3;
      color: $text-scale-light;
      @include for-screen-up($desktop-s) {
        max-width: 43.055vw;
      }
    }
  }
  .heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 4px;
    @include for-screen-up($desktop-s){
      margin-bottom: 0.556vw;
    }
    @include for-screen-down($desktop-s){
      margin-bottom: 1rem;
    }
    h1{
      @extend %dialog-title;
      color: $text-scale;
    }
    .modal-close-button{
      @include makeRound(64px);
      background: $light-grey;
      cursor: pointer;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      @include for-screen-up($desktop-s){
        @include makeRound(4.44vw);
        img{
          width: 1.25vw;
        }
      }
      @include for-screen-down($desktop-s){
        position: fixed;
        top: 40px;
        right: 40px;
      }
      @include for-screen-down($mobile-l){
        @include makeRound(48px);
        top: 24px;
        right: 24px;
      }
      img{
        width: 1rem;
      }
    }
  }
  .modal-text-content{
    overflow: auto;
    display: grid;
    grid-template-columns: 59.52% 34.15%;
    gap: 6.33%;
    @include for-screen-up($desktop-s){
      padding: 1.111vw 0;
    }
    @include for-screen-between($desktop-s, $desktop-m){
      grid-template-columns: 1fr auto !important;
    }
    @include for-screen-down($tablet-s){
      gap: 60px;
      grid-template-columns: 100%;
    }
  }
  .speciality-description{
    @include for-screen-up($desktop-s){
      margin-bottom: 2.77vw;
    }
    p{
      @extend %body4;
      color: $text-scale-light;
      &:not(:last-of-type){
        @include for-screen-up($desktop-s) {
          margin-bottom: 1.11vw;
        }
      }
    }
  }
  .speciality-products{
    @include for-screen-down($desktop-s){
      margin-top: 60px;
    }
  }
  .speciality-products__list{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    @include for-screen-up($desktop-s){
      gap: 0.833vw;
    }
    span{
      @extend %tag-text;
      background: $light-grey;
      border-radius: 50px;
      color: $text-scale;
      padding: 10px 16px 8px;
      @include for-screen-up($desktop-s){
        border-radius: 3.472vw;
        padding: 0.694vw 1.111vw 0.555vw;
      }
    }
  }
  .speciality-key-points__list {
    li {
      @extend %body4;
      color: $text-scale;
      position: relative;
      padding-left: 22px;
      white-space: nowrap !important;
      @include for-screen-up($desktop-s){
        padding-left: 1.527vw;
      }
      &:not(:last-of-type) {
        margin-bottom: 8px;
        @include for-screen-up($desktop-s){
          margin-bottom: 0.556vw;
        }
      }

      &::before {
        content: "";
        @include makeRound(5px);
        background-color: $color-primary;
        position: absolute;
        top: 10px;
        left: 0;
        @include for-screen-up($desktop-s){
          @include makeRound(0.347vw);
          top: 0.625vw;
        }
      }
    }
  }
  .modal-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include for-screen-up($desktop-s){
      padding-top: 0.556vw;
      &::before{
        content: "";
        background: linear-gradient(0deg, #FFFFFF 10%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        ight: 3.125vw;
        position: absolute;
        top: -3.055vw;
        left: 0;
      }
    }

    .btn-wrapper{
      display: flex;
      gap: 12px;
      @include for-screen-up($desktop-s){
        gap: 0.833vw;
      }
      button{
        @include makeRound(56px);
        background: $background;
        border: 1px solid $text-scale;
        color: $text-scale;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        @include for-screen-up($desktop-s){
          @include makeRound(3.889vw);
          padding: .55vw;
          img{
            width: 1.666vw;
            height: 1.666vw;
          }
        }
        &:disabled{
          cursor: default;
          opacity: .2;
        }
        @media (hover: hover) {
          &:hover{
            background: $light-grey;
          }
        }
      }
    }
    .contact-link{
      @extend %ctaSecondary;
      background: $color-primary;
      border-radius: 50px;
      color: white;
      padding: 1.188rem 1.5rem 1rem;
      cursor: pointer;
      @include for-screen-up($desktop-l){
        border-radius: 3.472vw;
        padding:  1.388vw 1.7vw 1.180vw;
      }
      @include for-screen-down($mobile-l){
        position: absolute;
        left: calc(50% - 109.3px);
        bottom: 32px;
      }
    }
  }
  .modal-spaciality__image{
    img{
      @include for-screen-up($desktop-s){
        height: 100vh;
        width: auto;
      }
      @include for-screen-down($desktop-s){
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
  }

}
