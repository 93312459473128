@use "fonts" as *;
@use "breakpoints" as *;

$PPMori: 'PP Mori', sans-serif;
$PPEditorialNew: 'PP Editorial New', serif;

/****************************************
 **************  TITLES  ****************
 ****************************************/

/* Display */
%display{
  font-family: $PPMori;
  font-size: 7.8vw;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -1.12px;
  text-align: center;
  @include for-screen-between($mobile-l, $desktop-s){
    font-size: 9vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 11.2vw;
    line-height: normal;
    letter-spacing: 0;
  }
}

/* Display - Editorial */
%display-editorial{
  font-family: $PPEditorialNew;
  font-size: 7.8vw;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  @include for-screen-between($mobile-l, $desktop-s){
    font-size: 9vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 11.2vw;
    line-height: normal;
  }
}

/* Title 1 */
%title1{
  font-family: $PPMori;
  font-style: normal;
  font-weight: 600;
  font-size: 5.25vw;
  line-height: 120%;
  @include for-screen-between($mobile-l, $tablet-s){
    font-size: 8.25vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 10vw;
    letter-spacing: -0.02375rem;
  }
}
%title1-editorial{
  font-family: $PPEditorialNew;
  font-style: italic;
  font-weight: 400;
  font-size: 5.25vw;
  line-height: 125%;
  @include for-screen-between($mobile-l, $tablet-s){
    font-size: 8.25vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 10vw;
    letter-spacing: -0.02375rem;
  }
}

/* Titles/Title 2 */
%title2 {
  font-family: $PPMori;
  font-weight: 475;
  line-height: 76.8px;
  letter-spacing: -0.01em;
  text-align: left;
  @include for-screen-up($tablet-m){
    font-size: 4.444vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 2rem;
    line-height: 33.6px;

  }
}


/* Titles/Title 22 - Medium */
%title2Medium {
  font-family: $PPMori;
  font-size:2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.64px;
  @include for-screen-up($desktop-l){
    font-size: 2.222vw;
  }
}
/* Titles/Title 2 - Semibold */
%title2SemiBold {
  font-family: $PPMori;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
  @include for-screen-up($desktop-l){
    font-size: 4.445vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 2.625rem;
  }
}

/* Title 3 */
%title3 {
  font-family: $PPMori;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 125%;
  letter-spacing: -0.32px;
  @include for-screen-up($desktop-l){
    font-size: 2.223vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 1.625rem;
  }
}

/* Title 4 */
%title4 {
  font-family: $PPMori;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -0.01em;
  line-height: 140%;
  @include for-screen-up($desktop-l){
    font-size: 1.944vw;
  }
}

/* Title 5 */
%title5 {
  font-family: $PPMori;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0;
  @include for-screen-up($desktop-l){
    font-size: 1.4vw;
  }
}

/* Body 1 */
%body1{
  font-family: $PPMori;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem; //24px
  line-height: 150%;
  @include for-screen-up($desktop-l){
    font-size: 1.7vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 1.125rem;
  }
}

/* Body 2 */
%body2{
  font-family: $PPMori;
  font-size: 1.375rem; //22px
  font-weight: 400;
  line-height: 150%;
  @include for-screen-down($tablet-m){
    font-size: 1.125rem;
  }
  @include for-screen-up($desktop-l){
    font-size: 1.52vw;
  }
}

/* Body texts/Body 3 */
%body3 {
    font-family: $PPMori;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 150%;

    @include for-screen-up($desktop-l){
      font-size: 1.25vw;
    }
}

/* Body 4 */
%body4{
  font-family: $PPMori;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  @include for-screen-up($desktop-l){
    font-size: 1.112vw;
  }
}

/* Body texts/Body MAJ */
%bodyTextUppercase {
  font-family: $PPMori;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  @include for-screen-up($desktop-l){
    font-size: 1.4vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }
}

/* Cta text */
%ctaTxt{
  font-family: $PPMori;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  @include for-screen-up($tablet-m){
    font-size: 1.94vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 22px;
  }
}

/* CTA Secondary */
%ctaSecondary {
  font-family: $PPMori;
  font-size: 1.125rem;
  font-weight: 475;
  line-height: 21px;
  letter-spacing: 0;
  @include for-screen-up($desktop-l){
    font-size: 1.25vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 1rem;
  }
}

/* Box info */
%box-info{
  font-family: $PPMori;
  font-size: 28px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0;
}

/* Hover Circle Text */
%hover-circle-text{
  font-family: $PPMori;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @include for-screen-down($tablet-m){
    font-size: 12px;
    line-height: 14px;
  }
}

/* Mobile/Special styles/Tags */
%tag-text {
  font-family: $PPMori;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  @include for-screen-up($desktop-l){
    font-size: 1.11vw;
  }
}

/* Mobile/Special styles/Contact - Mori */
%title-contact{
  font-family: $PPMori;
  font-size: 9vw;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -1.12px;
  text-align: center;
  @include for-screen-between($mobile-l, $tablet-l){
    font-size: 12vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 3.875rem;
  }
}

/* Special styles/Contact - Editorial */
%title-contact-editorial{
  font-family: $PPEditorialNew;
  font-size: 9vw;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  @include for-screen-between($mobile-l, $tablet-l){
    font-size: 12vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 3.875rem;
  }
}

/* Special styles/Menu */
%menu-link {
  font-family: $PPMori;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.36px;
  @include for-screen-up($desktop-l){
    font-size: 1.25vw;
  }
  @include for-screen-down($desktop-s){
    font-size: 14px;
  }
}

/* Special styles/Caption Text */
%caption-text {
  font-family: $PPMori;
  font-size: 1.375rem; //22px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  @include for-screen-up($desktop-l){
    font-size: 1.528vw;
  }
  @include for-screen-down($tablet-m){
    font-size: 18px;
  }
}

//Special styles/Pills Text;
%pills-text {
  font-family: $PPMori;
  font-weight: 475;
  line-height: 105%;
  text-align: center;
  @include for-screen-up($tablet-l){
    font-size: 1.667vw;
  }
  @include for-screen-between($tablet-s, $tablet-l){
    font-size: 1rem;
  }
  @include for-screen-down($tablet-s){
    font-size: 1.25rem;
  }
}

// Dialog Title
/* Title 1 */
%dialog-title{
  font-family: $PPMori;
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 120%;
  @include for-screen-up($desktop-s){
    font-size: 5.277vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 2.375rem;
  }
}
%dialog-title-editorial{
  font-family: $PPEditorialNew;
  font-style: italic;
  font-weight: 400;
  font-size: 4rem;
  line-height: 125%;
  letter-spacing: -0.01em;
  @include for-screen-up($desktop-s){
    font-size: 5.277vw;
  }
  @include for-screen-down($mobile-l){
    font-size: 2.375rem;
  }
}
