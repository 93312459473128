@use "../utils/index" as *;
@use "../components/projectPage/index" as *;

.explomaker-project {
  .graphic-axis .two-pgph-block{
    @include for-screen-up($tablet-m) {
      margin-bottom: 8.333vw;
    }
    @include for-screen-down($tablet-m) {
      margin-bottom: 5rem;
    }
  }
  .logotype-sct {
    .logo-video-wrapper{
      @include for-screen-up($tablet-m) {
        margin-bottom: 16.667vw;
      }
      @include for-screen-down($tablet-m) {
        margin-bottom: 10rem;
      }
    }
    .two-pgph-block{
      @include for-screen-up($tablet-m) {
        margin-bottom: 8.333vw;
      }
      @include for-screen-down($tablet-m) {
        margin-bottom: 5rem;
      }
    }
    .title-pgph-sct {
      @include for-screen-up($tablet-m) {
        margin: 16.6667vw 0 8.333vw;
        padding: 8.333vw 0;
      }
      @include for-screen-down($tablet-m) {
        margin: 10rem 0 5rem;
        padding: 5rem 0;
      }
    }
  }

  .fonts-colors-sct {
    .title-pgph-sct{
      @include for-screen-up($tablet-m) {
        margin-bottom: 8.333vw;
        padding: 8.333vw 0;
      }
      @include for-screen-down($tablet-m) {
        margin-bottom: 5rem;
        padding: 5rem 0;
      }
    }
    .pinterest-grid {
      .col-1 figure:first-of-type .image-wrapper,
      .col-2 figure:first-of-type .image-wrapper {
        aspect-ratio: 1/1;
      }

      .col-1 figure:nth-child(2),
      .col-1 figure:nth-child(3),
      .col-2 figure:nth-child(2) {
        .image-wrapper {
          aspect-ratio: 25/36;
        }
      }
    }
  }

  .screens-show-sct > .image-container,
  .app-launch-video-wrapper {
    @include for-screen-up($tablet-m) {
      margin: 16.6667vw 0;
    }
    @include for-screen-down($tablet-m) {
      margin: 7.5rem 0;
    }
  }
  .user-experience-sct .pinterest-grid{
    @include for-screen-up($tablet-m) {
      margin-top: 16.667vw;
    }
    @include for-screen-down($tablet-m) {
      margin-bottom: 7.5rem;
    }
  }
  .custom-list{
    margin-top: 2.5rem;
    @include for-screen-up($desktop-l){
      margin-top: 2.778vw;
    }
    @include for-screen-down($tablet-m){
      margin-top: 1rem;
      padding-top: 1rem;
    }
    li {
      @extend %body2;
      color: $text-scale;
      position: relative;
      padding-left: 22px;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }

      &::before {
        content: "";
        @include makeRound(5px);
        background-color: $color-primary;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }
}