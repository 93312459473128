@use 'utils/index' as *;

body{
  color: deeppink;
  font-family: $PPMori;
  background: $background;
}
@media (hover: hover) and (pointer: fine) {
  .link:hover {
    .link-underline::after {
      width: 100%;
    }
  }
  .link-underline::after{
      content: "";
      position: relative;
      bottom: 5px;
      display: block;
      width: 0;
      height: 1px;
      background: currentColor;
      transition: width 0.3s cubic-bezier(0.6, 0, 0.2, 1);
      @include for-screen-up($desktop-l){
        height: 0.07vw;
      }
  }
}

.flashing-dot{
  display: block;
  @include makeRound(8px);
  background: $color-primary-light;
  @include for-screen-up($tablet-l){
    @include makeRound(0.55vw);
  }
  @include for-screen-between($tablet-s, $tablet-l){
    @include makeRound(6px);
  }
  @include for-screen-down($tablet-s){
    @include makeRound(7px);
  }
}

.pills{
  .pills-text{
    display: inline-block;
    position: relative;
    top: 2px;
    @extend %pills-text;
  }
}
