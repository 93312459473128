@use "../../utils/index" as *;

/********  IMAGE WRAPPER  ********/
.image-wrapper {
  border-radius: 24px;
  overflow: hidden;
  @include for-screen-up($desktop-l){
    border-radius: 1.66vw;
  }
  @include for-screen-down($tablet-s){
    border-radius: 16px;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video{
    width: 100%;
    border-radius: 1.5rem;
    @include for-screen-up($desktop-l){
      border-radius: 1.66vw;
    }
    @include for-screen-down($tablet-s){
      border-radius: 1rem;
    }
  }
}
/********  BANNER  ********/
.banner{
  aspect-ratio: 2/1;
  overflow: hidden;
  width: 100vw;
  @include for-screen-down($mobile-l){
    aspect-ratio: 1/1;
  }
  img{
    width: 100%;
  }
}
/********  IMAGE CONTAINER  ********/
.image-container{
  &:not(:last-of-type){
    margin-bottom: 5rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 5.556vw;
    }
    @include for-screen-down($tablet-m){
      margin-bottom: 2.5rem;
    }
  }
  .image-container__caption{
    color: $text-scale;
    display: block;
    @extend %caption-text;
    margin-top: 1rem;
    text-align: center;
    @include for-screen-up($desktop-l){
      margin-top: 1.111vw;
    }
    @include for-screen-down($tablet-m){
      margin-top: .75rem;
    }
  }
}
/********  BANNER SLIDER ********/
.banner-slider-ctnr{
  margin: 0 auto;
  swiper-container{
    &::part(container) {
      @include for-screen-up($desktop-l){
        border-radius: 1.66vw;
      }
      @include for-screen-up($mobile-l) {
        border-radius: 24px;
      }
    }
    &::part(wrapper){
      width: 100%;
      transition-duration: 0s !important;
      transition-timing-function: unset !important;
    }
    .swiper-slide{
      width: 100%;
      @include for-screen-down($mobile-l) {
        aspect-ratio: 1/1;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.video-wrapper{
  border-radius: 24px;
  overflow: hidden;
  @include for-screen-up($desktop-l){
    border-radius: 1.66vw;
  }
  @include for-screen-down($tablet-s){
    border-radius: 16px;
  }
  video{
    width: 100%;
    border-radius: 1.5rem;
    @include for-screen-up($desktop-l){
      border-radius: 1.66vw;
    }
    @include for-screen-down($tablet-s){
      border-radius: 1rem;
    }
  }
}
