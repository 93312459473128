@use "../utils/index" as *;
@import "../../components/home/Header/header";
@import "../../components/home/HeroSection/heroSection";
@import "../../components/home/About/about";
@import "../../components/home/AboutBrands/aboutBrands";
@import "../../components/home/Specialities/specialities";
@import "../../components/home/ProjectCard/projectCard";
@import "../../components/home/ProjectsSection/projectsSection";
@import "../../components/home/Contact/contact";
@import "../../components/home/Footer/footer";

.homepage {
  section{
    position: relative;
    .anchor{
      position: absolute;
      top: -5vw;
    }
  }
  section:not(.hero, .about-brands) {
    padding: 0 8.34vw;
    margin: 10.71vw 0;
    @include for-screen-between($tablet-m, $desktop-m){
      margin: 11.72vw 0;
    }
    @include for-screen-down($mobile-l) {
      margin: 5rem 0;
    }
    .sub-section:not(:last-of-type) {
      margin-bottom: 13.889vw;
      @include for-screen-down($tablet-m) {
        margin-bottom: 8.75rem;
      }
    }
  }
  h2{
    @extend %title1;
    color: $text-scale;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 1.66vw;
      padding-bottom: 1.11vw;
    }
    @include for-screen-down($tablet-l){
      margin-bottom: 1rem;
    }
    span{
      display: inline-block;
    }
    .editorial{
      @extend %title1-editorial;
    }
  }

  /********  RANDOM PROJECTS SECTION  ********/
  .random {
    @include for-screen-up($tablet-s) {
      display: grid;
      grid-template-columns: 1fr 41.67vw;
      align-items: flex-start;
      column-gap: 10%;
    }
    @include for-screen-between($desktop-s, $desktop-m) {
      grid-template-columns: 37% 52%;
      column-gap: 11%;
    }

    h2 {
      grid-area: 1 / 1;
      align-self: flex-end;
    }

    .random-projects__text {
      @include for-screen-up($tablet-s) {
        grid-area: 2 / 1;
      }

      p {
        @extend %body3;
        @include for-screen-down($paragraph-breakpoint-font-size){
          font-size: 1rem;
        }
      }
      .projects-description-wrapper{
        @include for-screen-up($tablet-l) {
          padding-left: 4.17vw;
        }
      }
      .random-projects__text--client {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-bottom: 1rem;
        height: 1.5rem;

        .label {
          color: $text-scale;
          margin-right: 8px;
        }

        .client-name-wrapper {
          display: inline-block;
          overflow: hidden;
          position: relative;
          height: 1.5rem;
        }

        .random-projects__client-name {
          display: inline-block;
          color: $color-primary;
          font-weight: 500;
          width: 100%;
          position: absolute;
          left: 0;
          top: 100%;
          transform: skewY(30deg);
          transform-origin: top left;
          transition: top .4s ease .3s, transform .4s ease .3s;

          &.active {
            opacity: 1;
            top: 0;
            transform: skewY(0);
            //animation: showTextProject .4s ease .3s;
          }

          &:not(.active) {
            animation: hideTextProject .4s;
            //transition: opacity .4s ease;
          }
        }

      }

      .random-projects__text--description {
        color: $text-scale-light;
        &:not(.active) {
          display: none;
        }
      }
    }

    .random-projects__preview {
      @include for-screen-up($tablet-s) {
        grid-column: 2;
        grid-row: 1/span 2;
      }
      position: relative;
      width: 100%;

      img {
        width: 100%;
      }

      .random-projects__cursor {
        cursor: pointer;
        position: absolute;
        background: $color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        transform: translate3d(0, 0, 0);
        transition: all .5s ease;
        will-change: transform;

        @include for-screen-up($tablet-s) {
          @include makeRound(150px);
          top: calc(50% - 75px) !important;
          left: -75px !important;
        }

        @include for-screen-down($tablet-s) {
          @include makeRound(110px);
          right: 0;
          bottom: -32px;
          p {
            transform: rotate(15deg) !important;
          }
        }

        p {
          color: $background;
          text-align: center;
          @extend %hover-circle-text;
          transform: rotate(-15deg);
          span {
            display: block;
          }
        }
      }

      //.random-projects__cursor.follow {
      //  transform: translate3d(-50%, -50%, 0);
      //}

      .random-projects__list-image {
        aspect-ratio: 1/1;
        border-radius: 1.5rem;
        overflow: hidden;
        position: relative;
        @include for-screen-up($desktop-xl){
          border-radius: 1.66vw;
        }
        @include for-screen-down($mobile-m){
          transform: translateX(-8.34vw);
          width: 100vw;
          border-radius: 0;
        }

        .random-projects__list-image--item {
          position: absolute;
          left: 0;
          height: 100%;
          width: 100%;

          &:not(.active) {
            top: 100%;
            transition: top 0s ease-out .4s;
            z-index: 0;
          }

          &.active {
            top: 0;
            z-index: 2;
            transition: top .4s ease-out;
          }
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @include for-screen-down($tablet-s) {
      .random-projects__text {
        margin-top: 2.5rem;
      }
    }
  }
}
