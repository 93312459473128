@use 'src/styles/utils/index' as *;

.header{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  will-change: transform;
  z-index: 10;
  padding: 3.055vw 8.34vw;
  @include for-screen-down($desktop-s){
    padding: 2rem 8.34vw;
  }
  .logo img{
    width: 4.5rem;
    @include for-screen-up($desktop-l){
      width: 5vw;
    }
    @include for-screen-down($tablet-l){
      width: 3.4rem;
    }
  }
  .header__menu{
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 28px;
    align-items: baseline;
    @include for-screen-down($tablet-s){
      display: none;
    }
    .menu-link{
      @extend %menu-link;
      position: relative;
      color: white;
      cursor: pointer;
      padding: 4px 16px;
      @include for-screen-up($desktop-l){
        padding: 0.278vw 1.111vw;
      }
      @include for-screen-down($desktop-s){
        padding: 4px;
      }
      .super-script{
        position: absolute;
        top: -12px;
        font-size: 12px;
        font-weight: 500;
        margin-left: 2px;
        @include for-screen-up($desktop-l){
          font-size: 0.83vw;
        }
        @include for-screen-down($desktop-s){
          font-size: 8px;
          top: -4px;
        }
      }
    }
  }
}