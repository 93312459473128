@use 'src/styles/utils/index' as *;

/* Presentation */
.about-intro {
  @include for-screen-up($tablet-s) {
    gap: 6%;
    display: grid;
    grid-template-columns: 31.7% 62.3%;
    align-items: center;
  }
  @include for-screen-between($tablet-s, $desktop-m){
    align-items: flex-start;
  }
  @include for-screen-down($tablet-s) {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 80px;
  }

  .image-wrapper {
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    @include for-screen-between($mobile-l, $tablet-s){
      width: 60%;
      max-width: 380px;
    }
    @include for-screen-up($desktop-l){
      border-radius: 1.66vw;
    }
    img {
      width: 100%;
      cursor: pointer;
    }
    .location {
      position: absolute;
      bottom: 1.5rem;
      background: white;
      border-radius: 3rem;
      padding: 1.25rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-scale;
      @include for-screen-up($desktop-l){
        border-radius: 3.3vw;
        bottom: 1.6vw;
        gap: 0.83vw;
        padding: 1.38vw 1.7vw;
      }
      @include for-screen-between($tablet-s, $desktop-s){
        bottom: 1.125rem;
        gap: 8px;
        padding: .875rem 1.125rem;
      }
      @include for-screen-down($tablet-s){
        bottom: 1.25rem;
        gap: 8px;
        padding: 1.125rem 1.25rem;
      }
      .world-lottie {
        width: 1.5rem;
        height: 1.5rem;
        @include for-screen-up($desktop-l){
          width: 1.66vw;
          height: 1.66vw;
        }
        @include for-screen-between($tablet-s, $desktop-s){
          width: 1.25rem;
          height: 1.25rem;
        }
        @include for-screen-down($tablet-s){
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .about-intro__presentation {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    column-gap: 4%;
    @include for-screen-down(1338px) {
      grid-template-columns: 100%;
      row-gap: 16px;
    }
    @include for-screen-up($tablet-l) {
      padding-left: 5vw;
    }

    p {
      @extend %body3;
      color: $text-scale-light;
      @include for-screen-down($paragraph-breakpoint-font-size){
        font-size: 1rem;
      }

      a {
        color: $color-primary;
        display: block;
        width: fit-content;
      }
    }
  }
}