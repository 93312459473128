/* COLORS DEFINITION */
body{
  --background: #ffffff;
  --color-primary: #0F7847;
  --color-primary-light: #30BB7A;
  --symbole-gradient: linear-gradient(251.44deg, rgba(22, 24, 24, 0) 0%, rgba(22, 24, 24, 0.08) 100%);
  --orbital-gradient: conic-gradient(from 180deg at 50% 50%, rgba(22, 24, 24, 0) 0deg, rgba(22, 24, 24, 0) 52.5deg, #171717 52.54deg, rgba(22, 24, 24, 0) 360deg);
  --text-scale: #161818;
  --text-scale-op: 22, 24, 24;
  --text-scale-light: rgba(22, 24, 24, .6);
  --primary-reversed: #30BB7A;
  --background-footer: rgba(22, 24, 24, .03);
  --hero-green-gradient: radial-gradient(100% 100% at 50% 0%, #64A58A 0%, #76B6A1 100%);
  --light-grey: rgba(229, 238, 240, 0.50);
}

$background: var(--background);
$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$symbole-gradient: var(--symbole-gradient);
$orbital-gradient: var(--orbital-gradient);
$text-scale: var(--text-scale);
$text-scale-op: var(--text-scale-op);
$text-scale-light: var(--text-scale-light);
$text-scale-dark: var(--text-scale-dark);
$primary-reversed: var(--primary-reversed);
$background-footer: var(--background-footer);
$hero-green-gradient: var(--hero-green-gradient);
$light-grey: var(--light-grey);