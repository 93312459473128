@use 'src/styles/utils/index' as *;

.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  background: $background-footer;
  padding: 2.5rem 8.34vw;
  @include for-screen-up($desktop-l){
    padding: 2.77vw 8.34vw;
  }
  @include for-screen-down($tablet-s){
    flex-direction: column;
    .footer__rights{
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1.5rem !important;
    }
    .footer__creators{
      text-align: center;
    }
  }


  p, a, span{
    @extend %body4;
  }
  p{
    color: $text-scale-light;
  }

  .footer__rights{
    display: flex;
    gap: 1.5rem;
    @include for-screen-up($desktop-l){
      gap: 1.66vw;
    }
    .logo{
      width: 4.7vw;
      @include for-screen-down($tablet-m){
        width: 4.25rem;
      }
    }
  }
  .footer__creators{
    display: flex;
    gap: 2.5rem;
    @include for-screen-up($desktop-l){
      gap: 2.77vw;
    }
    @include for-screen-down($mobile-s){
      flex-direction: column;
      gap: 1.5rem;
    }
    .footer__creators--name{
      color: $text-scale;
    }
    .link-underline::after{
      background: $text-scale !important;
    }
  }
}