@use 'src/styles/utils/index' as *;

.projects-list__item {
  height: 100%;
  position: relative;
  color: $text-scale;
  transition: all .4s ease;
  @include for-screen-up($desktop-s) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.8vw 5.58vw !important;
  }
  @include for-screen-down($desktop-s) {
    padding: 2.5rem 0;
    display: block;
    .projects-list__item--title{
      margin-bottom: .75rem !important;
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background: $text-scale-light;
    opacity: .1;
    position: absolute;
    bottom: 0;
    left: 0;
    @include for-screen-up($desktop-l){
      height: 0.07vw;
    }
  }

  .projects-list__item--title {
    @extend %title2;
    margin-bottom: 8px;
    transition: color .4s ease;
  }

  .projects-list__item--sub {
    @extend %body3;
    opacity: .6;
  }

  .projects-list__item--preview {
    aspect-ratio: 1/1;
    border-radius: 1.5rem;
    display: block;
    margin-bottom: 1.5rem;
    overflow: hidden;
    @include for-screen-up($desktop-s) {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
      object-position: center;
    }
  }
}
a.projects-list__item.active{
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) and (min-width: $desktop-s){
    &:hover {
      padding: 3.8vw !important;
      .projects-list__item--title{
        color: $color-primary;
      }
    }
  }
}
.projects-list__item-coming-soon{
  @media (hover: hover) and (pointer: fine) and (min-width: $desktop-s){
    .projects-list__item--title {
      opacity: .2;
    }
  }
  .projects-list__item--tag{
    @extend %tag-text;
    line-height: normal;
    display: inline-block;
    color: $color-primary;
    border-radius: 32px;
    background: $light-grey;
    padding: 10px 16px 8px;
    @include for-screen-up($desktop-l){
      padding: 0.7vw 1.11vw 0.5vw;
      border-radius: 2.2vw;
    }
    @include for-screen-down($tablet-s){
      padding: 10px 16px 8px;
    }
  }
}