@use 'src/styles/utils/index' as *;
/********  HERO SECTION  ********/
.hero {
  background: center bottom / contain no-repeat url("/assets/images/portrait/hero_portrait.png"),$hero-green-gradient;
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  padding-top: 7.5rem;
  padding-bottom: 24px;
  position: relative;
  @include for-screen-up($desktop-l) {
    padding-bottom: 1.66vw;
  }
  @include for-screen-down($mobile-l) {
    height: 85vh;
    padding-top: 2rem;
  }

  .availability {
    display: flex;
    justify-content: center;
    align-items: center;
    .availability-pill {
      background: white;
      border-radius: 3rem;
      padding: 1.125rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 12px;
      color: $text-scale;
      @include for-screen-up($tablet-l){
        border-radius: 3.33vw;
        gap: 0.83vw;
        padding: 1.38vw 1.7vw;
      }
      @include for-screen-between($tablet-s, $tablet-l){
        gap: 8px;
        padding: .875rem 1.125rem;
      }
    }
  }
}

.title{
  display: flex;
  justify-content: center;
  position: relative;
  height: 42.25vw;
  overflow: hidden;
  top: 2rem;
  margin: auto 0;
  @include for-screen-up($desktop-l){
    top: 7vw;
  }
  @include for-screen-between($tablet-s, $desktop-s){
    height: 48vw;
  }
  @include for-screen-between($mobile-l, $tablet-s){
    height: 50vw;
  }
  @include for-screen-down($mobile-l){
    height: 65vw;
  }
  #title-container1, #title-container2{
    width: 271%;
    position: absolute;
    top: 1.875rem;
    opacity: 1;
    will-change: transform;
    @include for-screen-between($tablet-s, $desktop-s){
      width: 360%;
    }
    @include for-screen-down($tablet-s){
      width: 407%;
    }
    @include for-screen-down($mobile-l){
      width: 520%;
    }
  }
  #rounded-title-1, #rounded-title-2{
    .st2 {
      fill: white !important;
    }

    .st3 {
      fill: white !important;
    }
  }
// TODO TEMPORARILY
  #rounded-title-1{
    transform: rotate(29deg);
  }
}