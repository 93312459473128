@use 'src/styles/utils/index' as *;

.contact{
  margin-bottom: 0 !important;
  padding: 5rem 0 5rem !important;
  overflow: hidden;
  @include for-screen-up($tablet-l){
    padding: 3.33vw 0 5.55vw !important;
  }
  @include for-screen-down($mobile-l){
    padding: 2rem 0 5rem !important;
  }

  .contact-catch-phrase{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    padding: 2.75rem 0;
    @include  for-screen-up($desktop-l){
      margin-bottom: 5.55vw;
      padding: 3.05vw 0;
    }
    @include for-screen-down($tablet-s){
      margin-bottom: 2.5rem;
      padding: 1rem 0;
    }

    .contact-animated-circle{
      position: absolute;
      top: -25%;
      aspect-ratio: 1.05 / 2;
      height: 150%;
      transform: rotate(240deg);
      border-radius: 100%;
      border: 1px solid transparent;
      background: linear-gradient(to right, white, white), linear-gradient(to right, black , #ddd, white);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      z-index: -1;
      @include for-screen-down($tablet-s){
        height: 135%;
        top: -18%;
        transform: rotate(230deg);
      }
    }
    .contact-catch-phrase__text{
      span{
        color: $text-scale;
        display: block;
      }
      span:not(.editorial){
        @extend %title-contact;
      }
      .editorial{
        @extend %title-contact-editorial;
      }
    }
  }

  /* email contact */
  .email-contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin-bottom: 5rem;
    @include  for-screen-up($desktop-l){
      gap: 2.77vw;
      margin-bottom: 5.55vw;
    }
    @include for-screen-down($tablet-s){
      gap: 1.5rem;
    }
    p{
      @extend %bodyTextUppercase;
      color: $text-scale;
      text-align: center;
      span{
        display: block;
      }
    }
    .contact-btn{
      padding: 1.94vw 3.05vw;
      background-color: $color-primary;
      color: $background;
      cursor: pointer;
      border-radius: 7.5rem;
      overflow: hidden;
      position: relative;
      @include for-screen-down($tablet-m){
        padding: 1.5rem 2.5rem;
      }
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary-reversed;
        z-index: 1;
        transition: top .5s ease;
      }
      @media (hover: hover) and (pointer: fine){
        &:hover{
          &:after {
            top: 85%;
          }
        }
      }
      span{
        @extend %ctaTxt;
        display: block;
      }
      .contact-btn__text-ctnr{
        z-index: 2;
        position: relative;
        height: 2.08vw;
        overflow: hidden;
        display: flex;
        justify-content: center;
        @include for-screen-down($tablet-m){
          height: 27px;
        }
        span{
          transition: top .3s ease;
        }
      }
      .contact-btn__text---email{
        color: $background;
        position: relative;
        top: 1px;
        @include for-screen-down($tablet-m){
          height: 27px;
          top: 2px;
        }
      }
      .contact-btn__text--copied{
        color: $text-scale;
        position: absolute;
        top: 110%;
      }
    }
    .contact-btn.clicked{
      &:after {
        top: 0;
        transition: top .5s ease;
      }
      .contact-btn__text---email,
      .contact-btn__text--copied{
        transition: top .3s ease;
      }
      .contact-btn__text---email{
        top: -110%;
      }
      .contact-btn__text--copied{
        top: 1px;
        @include for-screen-down($tablet-m){
          top: 2px;
        }
      }
    }
  }

  /* social networks */
  .social-network-ctnr{
    display: flex;
    justify-content: center;
  }
  .social-network-contact{
    display: inline-flex;
    gap: 40px;
    min-width: 226px;
    @include  for-screen-up($desktop-l){
      gap: 2.77vw;
    }
    @include for-screen-down($mobile-l){
      display: grid;
      grid-template-columns: 36px 1fr;
      gap: 30px;
      .contact-type{
        text-align: center;
      }
    }
    @include for-screen-down($tablet-s){
      padding: 0 1.25rem;
    }
    .social-network__links{
      display: flex;
      align-items: center;
      gap: 30px;
      @include  for-screen-up($desktop-l){
        gap: 2.08vw;
      }
      @include for-screen-down($mobile-l){
        flex-direction: column;
        align-items: normal;
        gap: 10px;
      }
    }
    p, a{
      @extend %bodyTextUppercase;
      text-transform: uppercase;
    }
    p{
      color: $text-scale;
    }
    a{
      color: $color-primary;
      width: fit-content;
    }
  }

  /* CV link */
  .resume-link {
    margin-top: 12px;
    @include  for-screen-up($desktop-l) {
      margin-top: 0.83vw;
    }
    @include for-screen-down($mobile-l) {
      flex-wrap: wrap;
      margin-top: 32px;
    }
  }
}