@use 'src/styles/utils/index' as *;

.about-brands {
  overflow: hidden;
  @include for-screen-up($tablet-m){
    margin: 11.72vw 0 16.67vw;
  }
  @include for-screen-between($mobile-l, $tablet-m){
    margin: 5rem 0 12.5rem;
  }
  @include for-screen-down($mobile-l) {
    margin: 5rem 0 11.25rem;
  }
  .about-brands__text {
    padding: 0 8.33vw;
    margin-bottom: 2.5rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 2.7vw;
    }

    .about-brands__text--description {
      display: grid;
      grid-template-columns: repeat(2, 48%);
      column-gap: 4%;
      @extend %body3;
      color: $text-scale-light;
      @include for-screen-down($paragraph-breakpoint-font-size){
        font-size: 1rem;
      }
      @include for-screen-up($tablet-l) {
        padding-left: 7vw;
      }
      @include for-screen-down($tablet-s) {
        grid-template-columns: 100%;
        row-gap: 1rem;
      }
    }
  }

  .about-brands__grid-ctnr {
    display: flex;
    justify-content: center;

    .about-brands__logo-grid {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 0;
      img{
        width: 140vw;
      }
    }
  }
}