/************* Import Fonts with relative path from main index.scss *************/

/* PP Mori - Regular */
@font-face {
  font-family: "PP Mori";
  src: url("../../fonts/PPMori-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* PP Mori - Medium */
@font-face {
  font-family: "PP Mori";
  src: url("../../fonts/PPMori-Medium.ttf") format("truetype");
  font-weight: 500;
}

/* PP Mori - SemiBold */
@font-face {
  font-family: "PP Mori";
  src: url("../../fonts/PPMori-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

/* PP Editorial New - Regular Italic */
@font-face {
  font-family: "PP Editorial New";
  src: url("../../fonts/PPEditorialNew-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}