@use "../../utils/index" as *;
/********  HEADINGS SECTION  ********/
.headings{
  padding: 2.7778vw 0 8.8889vw;
  text-align: center;
  @include for-screen-down($tablet-l) {
    padding: 0.5rem 1.5rem 3rem;
  }
  h1{
    margin-top: 2.23vw;
    @include for-screen-down($tablet-l) {
      margin-top: 1rem;
    }
    &>span{
      color: $text-scale;
      display: block;
    }
    span:not(.editorial){
      @extend %display;
    }
    .editorial{
      @extend %display-editorial;
    }
  }
  .headings__client-name{
    @extend %body1;
    color: $text-scale-light;
  }
}
