@use "../utils/index" as *;

.loader{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $background;
  z-index: 100;
  opacity: 1;
  &.hide{
    top: -100%;
    opacity: 0;
    pointer-events: none;
    transition: all 0s;
  }
  .loader-logo{
    width: 45vw;
    @include for-screen-down($tablet-s){
      width: 70vw;
    }
  }
}