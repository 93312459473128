@use 'src/styles/utils/index' as *;

/********  PROJECTS SECTION  ********/
.projects {
  h2{
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    @include for-screen-up($tablet-l){
      margin-bottom: 2.77vw !important;
      padding-bottom: 2.22vw !important;
    }
    @include for-screen-down($tablet-l){
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .projects-list {
    @include for-screen-between($mobile-l, $desktop-s){
      display: grid;
      grid-template-columns: repeat(2, 47.5%);
      column-gap: 5%;
    }
    @include for-screen-down($mobile-l) {
      display: block;
    }
    li:last-of-type .projects-list__item {
      @include for-screen-down($tablet-s) {
        &::after {
          display: none;
        }
      }
    }
    li:first-of-type .projects-list__item {
      @include for-screen-up($desktop-s){
        &::before{
          content: "";
          width: 100%;
          height: 0.07vw;
          background: $text-scale-light;
          opacity: .1;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}