@use "../../utils/index" as *;

/********  PROJECTS SECTION  ********/
.other-case-studies {
  h2{
    text-align: center;
    @extend %title1;
    color: $text-scale;
    .editorial{
      @extend %title1-editorial;
    }
    margin-bottom: 2.5rem;
    padding-bottom: 1.125rem;
    @include for-screen-up($desktop-l){
      margin-bottom: 2.777vw;
      padding-bottom: 1.25vw;
    }
    @include for-screen-down($mobile-l){
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
  .projects-grid {
    @include for-screen-up($mobile-l){
      display: grid;
      grid-template-columns: repeat(2, 48%);
      column-gap: 4%;
    }
    @include for-screen-down($mobile-l){
      .project-card:first-of-type{
        &::after{
          content: "";
          background: rgba($text-scale-op, .1);
          display: block;
          height: 1px;
          width: 100%;
          margin: 2.5rem 0;
        }
      }
    }
  }
  .project-card{
    .image-wrapper{
      aspect-ratio: 1/1;
    }
    .project-card__caption{
      margin-top: 1.5rem;
      @include for-screen-up($tablet-m){
        margin-top: 1.666vw;
      }
    }
    .project-card__caption--name{
      @extend %title2Medium;
      color: $text-scale;
      margin-bottom: .5rem;
      @include for-screen-up($tablet-m){
        margin-bottom: 0.556vw;
      }
    }
    .project-card__caption--skills{
      @extend %body4;
      color: $text-scale-light;
    }
  }
}