
$mobile-s: 375px;
$mobile-m: 414px;
$mobile-l: 576px;

$tablet-s: 767px;
$tablet-m: 850px;
$tablet-l: 960px;

$desktop-s: 1024px;
$desktop-m: 1200px;
$desktop-l: 1440px;
$desktop-xl: 2060px;

$paragraph-breakpoint-font-size: 1115px;


@mixin for-screen-up($breakpoint){
  @media screen and (min-width: ($breakpoint + 1)) {
    @content;
  }
}

@mixin for-screen-down($breakpoint){
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin for-screen-between($breakpoint-min, $breakpoint-max){
  @media screen and (min-width: ($breakpoint-min + 1)) and (max-width: $breakpoint-max) {
    @content;
  }
}
