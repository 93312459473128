@use "../utils/index" as *;
@use "../components/projectPage/index" as *;

.cxmp-project {
  .branding .motion-video-wrapper,
  .branding .banner-slider-ctnr {
    @include for-screen-up($tablet-l) {
      padding: 0 13.8889vw;
    }
    @include for-screen-between($mobile-l, $tablet-l) {
      padding: 0 8.34vw;
    }
  }

  .fonts-colors-pinterest-grid {
    .image-wrapper:not(.col-1 figure .image-wrapper) {
      aspect-ratio: 25/36;
    }

    .col1 figure .image-wrapper {
      aspect-ratio: 1/1;
    }
  }

  .mockup-pinterest-grid {
    .col-1 figure:first-of-type .image-wrapper,
    .col-1 > .image-wrapper,
    .col-2 > .image-wrapper {
      aspect-ratio: 1/1;
    }

    .col-1 figure:nth-child(2) .image-wrapper,
    .col-2 figure .image-wrapper {
      aspect-ratio: 25/36;;
    }
  }

  .graphic-axis, .logotype-sct {
    .two-pgph-block {
      margin-bottom: 7.5rem;
      @include for-screen-up($desktop-l) {
        margin-bottom: 8.334vw;
      }
      @include for-screen-down($tablet-m) {
        margin-bottom: 5rem;
      }
    }
  }

  .fonts-colors-sct {
    .title-pgph-sct {
      padding: 7.5rem 0;
      margin-bottom: 7.5rem;
      @include for-screen-up($desktop-l) {
        padding: 8.333vw 0;
      }
      @include for-screen-down($tablet-m) {
        padding: 5rem 0;
        margin-bottom: 5rem;
      }
    }
  }

  .poster-banner {
    margin-top: 6.25rem;
    @include for-screen-up($desktop-l) {
      margin-top: 6.944vw;
    }
    @include for-screen-down($tablet-m) {
      margin-top: 2.5rem;
    }
  }

  .image-usage-sct .title-pgph-sct,
  .univers-homepage-sct .title-pgph-sct,
  .screens-show-sct .section-title,
  .screens-show-sct .pinterest-grid {
    @include for-screen-up($tablet-m) {
      margin-bottom: 16.6667vw;
    }
    @include for-screen-down($tablet-m) {
      margin-bottom: 5rem;
    }
  }

  .image-usage-pinterest-grid {
    .col-1 figure:first-of-type .image-wrapper,
    .col-1 > .image-wrapper,
    .col-2 > .image-wrapper {
      aspect-ratio: 1/1;
    }

    .col-1 figure:nth-child(2),
    .col-2 figure:first-of-type {
      .image-wrapper {
        aspect-ratio: 25/36;
      }
    }
  }

  .screens-show-sct {
    .title-pgph-sct {
      @include for-screen-up($mobile-l) {
        margin-bottom: 11.111vw;
      }
      @include for-screen-down($mobile-l) {
        margin-bottom: 80px;
      }
    }
  }
}