@use "../../utils/index" as *;

/********  PINTEREST GRID  ********/
.pinterest-grid {
  @include for-screen-up($mobile-l) {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 4%;
    align-items: center;
  }
  @include for-screen-down($mobile-l){
    .col-1{
      margin-bottom: 3.5rem;
    }
  }
  .col-1, .col-2{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    @include for-screen-up($desktop-l) {
      gap: 5.555vw;
    }
    @include for-screen-down($tablet-m) {
      gap: 3.5rem;
    }
    figcaption {
      padding: 32px 0 0;
      span {
        @extend %caption-text;
        display: block;
        color: $text-scale;
        text-align: center;
      }
    }
  }
  .slider-ctnr{
    border-radius: 24px;
    overflow: hidden;
    @include for-screen-up($desktop-l){
      border-radius: 1.66vw;
    }
  }
  swiper-container{
    &::part(wrapper){
      width: 100%;
      transition-duration: 0s !important;
      transition-timing-function: unset !important;
    }
    .swiper-slide{
      width: 100%;
    }
  }
}
