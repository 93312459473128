@use "./utils/index" as *;

#cursor {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  pointer-events: none;
  will-change: transform;
  //mix-blend-mode: difference;
}

#projects-hover-reveal{
  border-radius: 1.5rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  will-change: transform;
  overflow: hidden;
  z-index: 10;
  margin-top: -200px;
  margin-left: -200px;
  transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1),
  background-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
  border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
  width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
  height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  @include for-screen-up($desktop-l){
    border-radius: 1.66vw;
  }
  .projects-list-image,
  .projects-list-image__inner,
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .projects-list-image{
    .projects-list-image__inner{
      img{
        object-fit: cover;
      }
    }
  }
}

@media (hover: hover) and (pointer: fine) and (min-width: $desktop-s) {
  .cursor__circle {
    @include makeRound(1.66vw);
    margin-top: -50%;
    margin-left: -50%;
    border: solid 2px $color-primary;
    transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1),
    background-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
    border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
    width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
    height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }

  #cursor.cursor-on-link .cursor__circle,
  #cursor.cursor-hidden .cursor__circle{
    width: 0;
    height: 0;
    border-color: transparent;
  }

  #cursor.cursor-contact .cursor__circle,
  #cursor.cursor-project .cursor__circle{
    cursor: pointer;
    width: 150px;
    height: 150px;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after{
      font-family: $PPMori;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $background;
      padding: 0 7px;
      text-align: center;
    }
  }
  #cursor.cursor-contact .cursor__circle::after{
    content: "Contactez moi !";
  }
  #cursor.cursor-project .cursor__circle::after{
    content: "Voir le projet";
  }

  #projects-hover-reveal.active{
    width: 400px;
    height: 400px;
  }
}