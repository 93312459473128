/**
   KEYFRAMES
 */
@keyframes showTextProject {
  0%{
    transform: skewY(10deg);
    top: 100%;
  }
  100%{
    transform: skewY(0deg);
    top: 0;
  }
}

@keyframes hideTextProject {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
