@use "../../utils/index" as *;

.go-to-home-btn{
  cursor: pointer;
  background: $light-grey;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  @include makeRound(64px);
  position: fixed;
  top: 2.4rem;
  right: 8.34vw;
  z-index: 10;
  @include for-screen-up($desktop-l){
    @include makeRound(4.44vw);
    top: 2.57vw;
  }
  @include for-screen-down($tablet-m){
    @include makeRound(48px);
    top: 1.5rem;
    right: 1.5rem;
    padding: 1rem;
  }
  img{
    height: 1.125rem;
    width: 1.125rem;
    @include for-screen-up($desktop-l) {
      height: 1.25vw;
      width: 1.25vw;
    }
  }
}
.project-page{
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    will-change: transform;
    z-index: 10;
    padding: 3rem 8.333vw;
    position: unset;
    @include for-screen-up($desktop-l){
      padding: 3.333vw 8.333vw;
    }
    @include for-screen-down($tablet-m){
      padding: 2rem;
    }
    .logo img{
      width: 4.5rem;
      @include for-screen-up($desktop-l){
        width: 5vw;
      }
      @include for-screen-down($tablet-m){
        width: 3.4rem;
      }
    }
  }
  p{
    @extend %body2;
    color: $text-scale-light;
  }
  h3{
    @extend %title3;
    color: $text-scale;
  }
  img{
    width: 100%;
  }
  section{
    @include for-screen-up($tablet-l) {
      padding: 16.6667vw 13.8889vw;
    }
    @include for-screen-between($mobile-l, $tablet-l){
      padding: 13.88vw 11.111vw;
    }
    @include for-screen-down($mobile-l){
      padding: 5rem 1.5rem;
    }
  }
  .section-title{
    @extend %title2SemiBold;
    color: $text-scale;
    margin-bottom: 40px;
    padding-bottom: 20px;
    @include for-screen-up($desktop-l){
      padding-bottom: 1.39vw;
      margin-bottom: 2.778vw;
    }
  }

  .border-b{
    border-bottom: 1px solid rgba($text-scale-op, .2);
  }

  /********  CONTEXT SECTION  ********/
  .context{
    .context__content{
      @include for-screen-up($tablet-m) {
        display: grid;
        grid-template-columns: 26% 71%;
        gap: 3%;
        align-items: flex-start;
      }
      h3{
        margin-bottom: 16px; //useful only for mobile screen
      }
      p{
        @extend %body1;
        span{
          display: block;
          &:not(:last-of-type){
            margin-bottom: 1.667vw;
            @include for-screen-down($tablet-m){
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  /********  LOGOTYPE SECTION  ********/
  .logotype-pinterest-grid{
    .col-1, .col-2{
      .image-wrapper {
        &:first-of-type, &:nth-child(3){
          aspect-ratio: 1/1;
        }
        &:nth-child(2){
          aspect-ratio: 25/36;
        }
      }
    }
  }
}

/********  MOTION VIDEO SECTION  ********/
.motion-video-wrapper{
  @include for-screen-up($desktop-l) {
    padding: 0 5.556vw;
  }
  @include for-screen-between($tablet-m, $desktop-l) {
    padding: 0 5rem;
  }
  @include for-screen-down($tablet-m){
    padding: 0 1.5rem;
  }
}

.homepage-motion{
  margin: 0 auto;
  width: 95vw;
}


.screens-show-sct {
  .pinterest-grid {
    align-items: flex-start !important;

    .col-2 {
      @include for-screen-up($mobile-l) {
        padding-top: 18.055vw;
      }
    }
  }
}
